import React from "react";
import {Link} from "gatsby";

const ForDriversData = [
    {
        id: 6,
        title: `FOR DRIVERS`,
        content: 'From empowering drivers with knowledge to providing new levels of safety and savings, drivers are at the heart of the Drivosity Solution',
        location: `/for-drivers`,
        data: null,
    },
    {
        id: 6,
        title: `PROTECTING AND EMPOWERING DRIVERS`,
        content: (
            <>
                Drivers are the most visible asset of a delivery business, and at Drivosity, we understand they are critical to operations. Helping drivers execute safer, faster and more efficient deliveries ensures that delivery-based businesses can continue to thrive, while also supporting a positive incentive culture for drivers.
            </>
        ),
        location: `/for-drivers`,
        data: null,
    },
    {
        id: 6,
        title: `PROTECTING AND EMPOWERING DRIVERS`,
        content: (
            <>
                NEW Certification for Safe Drivers. <Link to="/drivosity-certified" className='drv-link'>CLICK HERE</Link> to learn more about DrivosityCertified.
            </>
        ),
        location: `/for-drivers`,
        data: null,
    },
    {
        id: 6,
        title: ``,
        content: 'Do you have questions about Drivosity?',
        location: `/for-drivers`,
        data: null,
    },
    {
        id: 6,
        title: ``,
        content: `
            MORE MONEY IN YOUR POCKETS,
            PEACE OF MIND
        `,
        location: `/for-drivers`,
        data: null,
    }
];

const ForDriversSliderData = [
    {
        id: 6,
        title: `Driver Inspired Platform`,
        content: (
            <>
                First word that comes to mind when I think about Drivosity is safe. It’s safer. It’s also awesome! I drive a 6-cylinder SUV, and <b>before Drivosity I was spending about $60 a day in gas. Now I’m spending $45 on gas every 2-3 days with Drivosity</b> because I’m not stomping the gas pedal.
            </>
        ),
        location: `/for-drivers`,
        data: {
            name: 'DUTCHES',
            position: 'Domino’s driver for 9 years'
        }
    },
    {
        id: 6,
        title: `Driver Inspired Platform`,
        content: (
            <>
                Drivosity is really helpful because I don’t feel rushed; It actually hurts me to be rushed. I don’t ever have to worry about “is that cop trying to stop me” because I know I’m doing everything right. <b>It takes a lot of pressure off my back</b>.
            </>
        ),
        location: `/for-drivers`,
        data: {
            name: 'MAYRIM',
            position: 'Papa John’s driver for 5 years'
        }
    },
    {
        id: 6,
        title: `Driver Inspired Platform`,
        content: (
            <>
                The drive scores that I get every time I come back from a run make me feel really good. My goal is to get a 100 every time I drive. Personally, Drivosity has helped me be a safer driver by following the speed limit, doing what is expected of me, and I’ve been a much better, safer driver because of it. <b>I want other drivers to know is that it can be done; I get a 100 DriveScore everyday.</b>
            </>
        ),
        location: `/for-drivers`,
        data: {
            name: 'DEBBIE',
            position: 'Domino’s driver for 3 years'
        }
    },
    {
        id: 6,
        title: `Driver Inspired Platform`,
        content: (
            <>
                My favorite thing about Drivosity would be how it helps us with our performance. It’s brought me a lot of awareness with my driving. <b>What I’ve learned from Drivosity is when you drive for a certain number of years you start developing habits and get a little too comfortable behind the wheel</b>. The biggest thing for me was that it brought awareness that I was hard corning so it helped build the habit of slowing down and being a safe driver overall.
            </>
        ),
        location: `/for-drivers`,
        data: {
            name: 'YEIMY',
            position: 'Domino’s driver for 6 years'
        }
    },
    {
        id: 6,
        title: `Driver Inspired Platform`,
        content: (
            <>
                <b>The benefits I’ve seen from using Drivosity is that I am more aware of certain things on the road now</b>. My driving habits are different because of that and that’s the biggest benefit I’ve seen. I am more aware of what’s going on around me while I’m driving.
            </>
        ),
        location: `/for-drivers`,
        data: {
            name: 'LANCE',
            position: 'Papa John’s driver for 10 years'
        }
    },
];

const ForDriversCollapseData = [
    {
        id: 6,
        title: `BETTER TIPS`,
        content: (
            <>Happy customers usually lead to better tips and increased satisfaction. By enabling customers to track delivery progress, customers can see if a driver is delayed by external factors like traffic, without the frustrating and tip-eroding activity of constantly calling the store for updates. This transparency also helps customers be ready to receive the delivery; reducing at-the-door time and ultimately helping drivers speed up delivery times.</>
        ),
        location: `/for-drivers`,
        data: null
    },
    {
        id: 6,
        title: `MORE DELIVERIES, MORE MONEY IN YOUR POCKETS`,
        content: (
            <>Drivosity reduces service times to customers by helping management teams gain insight into every stage of the delivery–from the point customers submit the order, to the production time, driver prep time, drive time, and to-the-door time with the customer. With reduced service times, drivers can take on more more deliveries per hour, which can translate to more money on tips.</>
        ),
        location: `/for-drivers`,
        data: null
    },
    {
        id: 6,
        title: `BETTER GAS MILEAGE`,
        content: (
            <>Safer driving leads to better gas mileage. Drivers who don’t overuse their brakes, use hard accelerations, or drive at erratic speeds have reported weekly fuel savings of as much as $20- $40 per week.</>
        ),
        location: `/for-drivers`,
        data: null
    },
    {
        id: 6,
        title: `LESS WEAR & TEAR`,
        content: (
            <>Veteran drivers that have used DriveScore® feedback to drive safely and improve their performance report less wear and tear on brakes and tires. That’s important because longevity of vehicles can be just as important as fuel economy when it comes to overall efficiency and related costs.</>
        ),
        location: `/for-drivers`,
        data: null
    }
];

const ForDriversTabCollapseData = [
    {
        id: 6,
        title: `Community Complaints`,
        content: (
            <>Drivosity provides a defense from community complaints about reckless delivery drivers. Some managers have reported that over 50% of community complaints about reckless delivery drivers are unfounded, and Drivosity helps validate when drivers are doing the right thing.</>
        ),
        location: `/for-drivers`,
        data: null
    },
    {
        id: 6,
        title: `Easy to use`,
        content: (
            <>
                <div className='mb-7'>
                    Unlike many other fleet management systems, Drivosity doesn’t add tasks for drivers to remember, like using their phone to input when they arrive or leave a pick up or delivery location. Instead, Drivosity uses specialized GPS technology to track delivery stages automatically without requiring driver interaction. In addition, our technology doesn’t require use of personal cell phones and data plans.
                </div>
                In most use cases, Drivosity partners with HTH to embed our device within branded car toppers that delivery operations are already using. Drivosity toppers use cordless, battery photosensor technology so that drivers don’t have to remember to thread power cords to cigarette lighters or to turn on the sign lighting when it gets dark out. Other non-branded device options are also available for high crime areas or company-owned vehicles.
            </>
        ),
        location: `/for-drivers`,
        data: null
    },
    {
        id: 6,
        title: `Reduced Accidents`,
        content: (
            <>At minimum, accidents can temporarily put drivers out of work. The severity of the accident, vehicle damage, and potential personal injuries typically impacts the length of time that a driver will not be able to earn a wage. Drivosity has been shown to reduce the frequency and duration of accidents.</>
        ),
        location: `/for-drivers`,
        data: null
    },
    {
        id: 6,
        title: `Increased Fairness`,
        content: (
            <>Experienced drivers know that the responsibility of professional delivery driving includes safe, fast and efficient deliveries. These drivers report that “Drivosity levels the playing field” by providing performance insight and incentives on all delivery objectives, not just delivery times. Testing shows that aggressive driving can only improve arrival times by about 90 seconds, due to traffic lights timed to speed limits and the frequency of stop signs in last mile delivery. However, speeding violations, hard accelerations, hard braking and hard cornering that accompanies aggressive driving styles can increase the likelihood of an accident by as much as 450 times over a driver driving within a safe DriveScore® range. Experienced drivers know that 90 seconds is not worth personal injury to self and others, multi-million dollar lawsuits against the brand they work for, or in worse cases, putting their employer out of business.</>
        ),
        location: `/for-drivers`,
        data: null
    },
    {
        id: 6,
        title: `Medical Emergencies`,
        content: (
            <>GPS tracking helps your team know if and when you are in need of help. Drivosity’s Driver Inspire Display flags drivers who have been stopped for extended periods of time, and the “Find Me” location sharing feature has helped save the lives of drivers who became incapable of calling for help because of medical conditions or armed robbery.</>
        ),
        location: `/for-drivers`,
        data: null
    },
    {
        id: 6,
        title: `Theft`,
        content: (
            <>Criminals often view delivery drivers as easy targets for vehicle theft and robbery. Drivosity’s GPS technology has helped recover stolen toppers, vehicles, and has even helped to bust chop shops.</>
        ),
        location: `/for-drivers`,
        data: null
    },
];

export {ForDriversData, ForDriversSliderData, ForDriversCollapseData, ForDriversTabCollapseData};
