import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import ForDrivers from "../components/OurCompany/ForDrivers/ForDrivers";

import Favicon from "../images/Favicon.png";

const ForDriversPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>For Drivers - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                      content="Drivosity equips restaurant delivery drivers with safety, efficiency, and financial benefits. Earn more with better tips, faster deliveries, and improved fuel economy. Discover how our GPS-enabled car toppers and DrivosityGO app support and protect drivers. Book a demo to learn more."/>
            </Helmet>
            <Layout component={<ForDrivers/>}/>
        </>
    )
}

export default ForDriversPage
