/**
 * DrvCover is shared Component which is used on common page of this Website.
 * Contains title of the page, description and specific image.
 *
 * To use this component you need to specify title, description, text-color and background-image.
 **/

import React from "react";

import {Container} from './DrvCover.styles';

const DrvCover = ({title, description, color, image}) => {
    return (
        <Container color={color} background={image}>
            <div className='container mx-auto flex flex-col justify-center'>
                <div className='drv-title'>
                    {title}
                </div>
                <div className='drv-description w-full md:w-1/2'>
                    {description}
                </div>
            </div>
        </Container>
    );
}

export default DrvCover;
