import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  font-size: 16px;
`

export const Tabs = styled.div`
  display: flex;
`

export const Tab = styled.div`
  display: flex;
  height: 50px;
  width: fit-content;
  padding: 14px 20px;
  margin: 3px 2px 3px 2px;
  text-align: center;
  align-items: center;
  border-color: #e3e3e3;
  background-color: #ebebeb;
  color: #666;
  border-radius: 40px;
  cursor: pointer;
  transition: background .2s ease-in-out,color .2s ease-in-out,border .2s ease-in-out;
  
  &.vertical {
    width: 100%;
    height: auto;
    text-align: left;
  }
  
  &:hover {
    background-color: #dcdcdc;
  }
  
  &.active {
    border-color: #f0f0f0;
    background-color: #f8f8f8;
    color: #666;
    cursor: default;
  }

  &.active:hover {
    background-color: #f8f8f8;
  }

  @media (max-width: 639px) { 
    height: auto;
    width: calc(50% - 4px);
    justify-content: center;

    &.vertical {
      width: calc(50% - 4px);
      text-align: center;
    }
  }
`

export const Content = styled.div`
  display: flex;
  padding: 30px 2px;
  color: #808080;
  
  &.vertical {
    padding: 20px 20px;
  }
  
  & .active {
    display: flex;
    width: 100%;
    animation-name: active;
    animation-duration: 1.5s;

    @keyframes active {
      from {
        opacity: 0;
      }

      to {
        opacity: 1;
      }
    }
  }
  
  & .inactive {
    display: none;
  }
`

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
`

export const Page = styled.div`
  display: block;
  text-decoration: none;
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 5px 7px;
  transition: all .2s ease-in-out;
  border-radius: 50%;
  background-color: #9dc7ed;

  &:hover {
    background-color: #5aa1e3;
  }

  &.active {
    background-color: #0e6faa;
  }

  &.active:hover {
    background-color: #0e6faa;
  }
`


