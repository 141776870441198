/**
 * DrvContent is shared Component which is used on common page of this Website.
 * Contains 4 type of contents: text and image, image and text, text only and image only.
 *
 * To use this component you need to specify array with types of content ex. ['text', 'image'],
 * array with html content, text-color and background-color.
 **/

import React from "react";

import {Container, Content} from './DrvContent.styles';

const DrvContent = ({type, content, background, color}) => {
    return (
        <Container background={background} color={color} className='container mx-auto flex flex-col xl:flex-row justify-center gap-x-4'>
            <Content type={type[0]} className='flex flex-col flex-1 justify-center'>
                {
                    content[0]
                }
            </Content>
            {
                content.length > 1 ? (
                    <Content type={type[1]} className='flex flex-col flex-1 justify-center'>
                        {
                            content[1]
                        }
                    </Content>
                ) : ''
            }
        </Container>
    );
}

export default DrvContent;
