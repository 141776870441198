/**
 * DrvVerticalCollapse is shared Component which is used on common page of this Website.
 * Contains title and collapsed contents.
 *
 * To use this component you need to specify title, and items which is array with objects.
 * Object contains to key's: title and description.
 **/

import React, {useState, useRef, useMemo, useEffect} from "react";
import {FiChevronDown} from "react-icons/fi";

import {Container, Collapse, Item, Icon, Description} from './DrvVerticalCollapse.styles';

const DrvVerticalCollapse = ({title, items}) => {
    const [active, setActive] = useState(null);
    const heights = useRef([]);

    useMemo(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', () => setActive(null));
        }
    }, []);

    useEffect(() => {
        setActive(0);
    }, []);

    return (
        <Container>
            <div className='container mx-auto flex flex-col justify-center'>
                <div className='drv-title'>
                    {title}
                </div>

                <Collapse>
                    {
                        items.map((item, index) => (
                            <div key={index}>
                                <Item className={index === active ? 'active' : 'inactive'} onClick={() => setActive(index)}>
                                    <div className='flex-1'>{item.title}</div>
                                    <Icon>
                                        <FiChevronDown />
                                    </Icon>
                                </Item>
                                <Description last={index === items.length - 1} height={heights.current[index] ? heights.current[index].clientHeight : 0} className={index === active ? 'open' : 'closed'}>
                                    <p ref={element => heights.current[index] = element}>
                                        {item.content}
                                    </p>
                                </Description>
                            </div>
                        ))
                    }
                </Collapse>
            </div>
        </Container>
    );
}

export default DrvVerticalCollapse;
