import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({height}) => height};
  width: ${({width}) => width};
  border-radius: 2px;
  cursor: pointer;

  background-image: url('${({image}) => image}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:hover div {
    background-color: #545454;
  }

  //@media screen and (max-width: 1250px){
  //  background-size: contain;
  //  height: 100vw;
  //}
`

export const Play = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  width: 86px;
  border-radius: 50%;
  background-color: #0e6faa;
  border: 4px solid #ffffff;
  transition: 0.3s;
`

export const VideoBg = styled.div`
  position: absolute;
  display: ${({open}) => open ? 'block' : 'none'};
  height: 100%;
  width: 100%;
  top: ${({top}) => top}px;
  left: 0px;
  background-color: #0000007a;
`

export const Video = styled.div`
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 10px 25px rgb(0 0 0 / 50%);
  background-color: #ffffff;
  padding: 10px;
  transition-timing-function: cubic-bezier(0, 0, 0.35,-0.2);
  height: 630px;
  width: 830px;
  top: ${({top}) => top}px;
  left: 50%;
  transform: translate(-50%, -50%);
  
  &.normal {
    display: none;
  }

  &.open {
    display: block;
    position: absolute;
    animation-name: open;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  &.close {
    animation-name: close;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
  }

  @keyframes open {
    from {
      height: 10px;
      width: 10px;
      top: ${typeof window !== 'undefined' ? window.innerHeight + window.scrollY : 0}px;
      transform: translate(-50%, 0);
      opacity: 0;
    }

    to {
      height: 630px;
      width: 830px;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }

  @keyframes close {
    from {
      height: 630px;
      width: 830px;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 1;
    }
    
    to {
      height: 10px;
      width: 10px;
      top: ${typeof window !== 'undefined' ? window.innerHeight + window.scrollY : 0}px;
      transform: translate(-50%, 0);
      opacity: 0;
    }
  }

  @media (max-width: 880px) {
    @keyframes open {
      from {
        height: 10px;
        width: 10px;
        top: ${typeof window !== 'undefined' ? window.innerHeight + window.scrollY : 0}px;
        transform: translate(-50%, 0);
        opacity: 0;
      }

      to {
        height: 630px;
        width: calc(100% - 40px);
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }

    @keyframes close {
      from {
        height: 630px;
        width: calc(100% - 40px);
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
      }

      to {
        height: 10px;
        width: 10px;
        top: ${typeof window !== 'undefined' ? window.innerHeight + window.scrollY : 0}px;
        transform: translate(-50%, 0);
        opacity: 0;
      }
    }
  }
`

export const Close = styled.img`
  position: absolute;
  top: -18px;
  right: -18px;
  height: 36px;
  width: 36px;
  cursor: pointer;
`
