import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  color: #545454;
  font-size: 18px;
`

export const Collapse = styled.div`
  border-top: 1px solid #e6e6e6;
  font-weight: 300;
`

export const Item = styled.div`
  display: flex;
  border: 1px solid #e6e6e6;
  border-top: none;
  padding: 12px 20px;
  cursor: pointer;
  transition: 0.2s;
  
  &:hover {
    background-color: #0e6faa;
    color: #ffffff;
  }
  
  &.active {
    background-color: #0e6faa;
    color: #ffffff;
  }
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
`

export const Description = styled.div`
  height: 0px;
  padding: 0px 30px;
  overflow: hidden;
  font-size: 16px;
  color: #808080;
  transition: height, 0.3s linear;
  
  &.open {
    height: ${({height}) => height + 40}px;
    padding: 20px 30px;
    border-bottom: ${({last}) => last ? 'none' : '1px solid #e6e6e6'};
    transition: height, 0.3s linear;
  }
`

