/**
 * DrvVideoPlayer is shared Component which is used on common page of this Website.
 * Contains image that open video-player(iframe) on click inside.
 *
 * To use this component you need to specify height, width, image and url for video.
 **/

import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";

import {Container, Play, VideoBg, Video, Close} from './DrvVideoPlayer.styles';
import CloseIcon from '../../../images/Icons/Close.svg';
import PlayIcon from '../../../images/Icons/Play.svg';

const DrvVideoPlayer = ({height, width, image, url}) => {
    /**
     * video.status possible values:
     * -1 - closing
     * 0 - normal
     * 1 - opening
     **/
    const [video, setVideo] = useState({
        top: 0,
        left: 0,
        status: 0,
        display: false
    })
    const containerRef = useRef(null);

    const visibilityHandler = useCallback((status) => {
        setVideo({
            top: containerRef.current.getBoundingClientRect().top,
            left: containerRef.current.getBoundingClientRect().left,
            status: status,
            display: false
        })
    }, []);

    useMemo(() => {
        if (video.status === 0) {
            if (typeof document !== "undefined") {
                document.body.style.overflow = 'auto';
            }
        } else {
            if (typeof document !== "undefined") {
                document.body.style.overflow = 'hidden';
            }
        }
    }, [video.status]);

    useEffect(() => {
        if (video.status === -1) {
            setTimeout(() => {
                setVideo(previous => {
                    return {
                        ...previous,
                        status: 0
                    }
                })
            }, 600)
        } else if (video.status === 1) {
            setTimeout(() => {
                setVideo(previous => {
                    return {
                        ...previous,
                        display: true
                    }
                })
            }, 600)
        }
    }, [video.status, setVideo])

    return (
        <div>
            <Container ref={containerRef} image={image} height={height} width={width} onClick={() => visibilityHandler(1)}>
                <Play>
                    <img src={PlayIcon} alt='Play Icon'/>
                </Play>
            </Container>

            <VideoBg top={typeof window !== `undefined` ? window.scrollY : 0} open={video.status !== 0} onClick={() => visibilityHandler(-1)}>
                {
                    <Video top={video.top} left={video.left} height={height} width={width}
                           className={video.status === 0 ? 'normal' : video.status === 1 ? 'open' : 'close'}>
                        <Close src={CloseIcon} onClick={() => visibilityHandler(-1)}/>
                        {
                            video.display ? (
                                <iframe width="100%"
                                        height="100%"
                                        src={url}
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                >
                                </iframe>
                            ) : video.status === 1 && (
                                <div className='drv-loader'>
                                    <div></div> <div></div> <div></div> <div></div> <div></div> <div></div>
                                </div>
                            )
                        }
                    </Video>
                }
            </VideoBg>
        </div>
    );
}

export default DrvVideoPlayer;
