import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  background-image: url('${({background}) => background}');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${({color}) => color};
  min-height: 380px;
  padding: 100px 0px;
`


