import React from "react";


import DrvCover from "../../Shared/DrvCover/DrvCover";
import DrvSubFooter from "../../Shared/DrvSubFooter/DrvSubFooter";
import DrvContent from "../../Shared/DrvContent/DrvContent";
import DrvVideoPlayer from "../../Shared/DrvVideoPlayer/DrvVideoPlayer";
import DrvSlider from "../../Shared/DrvSlider/DrvSlider";
import DrvVerticalCollapse from "../../Shared/DrvVerticalCollapse/DrvVerticalCollapse";
import DrvStoryComment from "../../Shared/DrvSlider/DrvStoryComment/DrvStoryComment";
import DrvTabCollapse from "../../Shared/DrvTabCollapse/DrvTabCollapse";
import {DrvButton} from "../../Shared/DrvButton/DrvButton";
import {CommentContainer} from "./ForDrivers.styles";
import {AiOutlineArrowRight} from "react-icons/ai";
import {ForDriversData, ForDriversSliderData, ForDriversCollapseData, ForDriversTabCollapseData} from "./ForDrivers.data";

import Cover from "../../../images/OurCompany/ForDrivers/Cover.jpg";
import VideoPhoto from "../../../images/OurCompany/ForDrivers/Video.jpg";

const ForDrivers = () => {
    return (
        <>
            {/*CONTENT*/}
            <DrvCover
                title={ForDriversData[0].title}
                description={ForDriversData[0].content}
                color='#ffffff'
                image={Cover}
            />

            {/*CONTENT*/}
            <div className="pt-12 pb-12">
                <DrvContent
                    type={['text', 'image']}
                    content={[
                        (
                            <>
                                <div className='drv-subtitle'>
                                    {ForDriversData[1].title}
                                </div>

                                <div className='drv-description'>
                                    <div className='mb-7'>
                                        {ForDriversData[1].content}
                                    </div>
                                    <div className='text-lg'>
                                        {ForDriversData[2].content}
                                    </div>
                               </div>
                            </>
                        ),
                        (
                            <DrvVideoPlayer height='500px'
                                            width='100%'
                                            image={VideoPhoto}
                                            url='https://www.youtube.com/embed/W6GPdYXoW0M'
                            />
                        )
                    ]}
                />
            </div>

            {/*CONTENT*/}
            <div className="py-12">
                <DrvVerticalCollapse title='MORE MONEY IN YOUR POCKETS' items={ForDriversCollapseData} />
            </div>

            {/*CONTENT*/}
            <CommentContainer style={{backgroundColor: '#f2f2f2'}}>
                <DrvSlider slides={ForDriversSliderData}
                           slide={DrvStoryComment}
                           sliderClass='story-comment-slider'
                           slideClass='story-comment-slide'
                           slidesPerView='auto'
                           effect='coverflow'
                           center={true}
                           pagination={true}
                />
            </CommentContainer>

            {/*CONTENT*/}
            <div className='py-12' style={{backgroundColor: '#f2f2f2'}}>
                <div className='container mx-auto'>
                    <div className='drv-subtitle'>PEACE OF MIND</div>
                    <DrvTabCollapse data={ForDriversTabCollapseData}/>
                </div>
            </div>

            {/*CONTENT*/}
            <DrvSubFooter
                description={ForDriversData[3].content}
                button={
                    <DrvButton to="/contact-us" background="var(--secondary-color)" bordercolor="white" className="w-fit">
                        CONTACT NOW
                        <AiOutlineArrowRight style={{fontSize: "17px"}} className="ml-3" />
                    </DrvButton>
                }
                color="#ffffff"
                background="#fe5a5e"
            />
        </>
    )
}

export default ForDrivers;
