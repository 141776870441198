/**
 * DrvTabCollapse is Shared Component which is used on common page of this Website.
 * Contains tabs and their contents.
 *
 * To use this component you need to specify direction of tabs['horizontal', 'vertical'] and data
 * which contains title and content.
 **/

import React, {useState} from "react";

import {Container, Tabs, Tab, Content, Pagination, Page} from './DrvTabCollapse.styles';

const DrvTabCollapse = ({direction = 'horizontal', data}) => {
    const [active, setActive] = useState(0);

    return (
        <Container className={`flex ${direction === 'vertical' ? 'flex-wrap sm:flex-nowrap' : 'flex-col'}`}>
            <Tabs className={`flex-wrap ${direction === 'vertical' ? 'sm:flex-col w-full sm:w-96' : 'sm:justify-center'}`}>
                {
                    data.map((item, index) => (
                        <Tab key={index} className={`${direction} ${index === active ? 'active' : ''}`} onClick={() => setActive(index)}>{item.title}</Tab>
                    ))
                }
            </Tabs>

           <div className={direction === 'vertical' ? 'flex flex-col w-full' : ''}>
               <Content className={direction}>
                   {
                       data.map((item, index) => (
                           <div key={index} className={`flex ${index === active ? 'active' : 'inactive'}`}>{item.content}</div>
                       ))
                   }
               </Content>

               <Pagination>
                   {
                       data.map((item, index) => (
                           <Page key={index} className={index === active ? 'active' : ''} onClick={() => setActive(index)}/>
                       ))
                   }
               </Pagination>
           </div>
        </Container>
    );
}

export default DrvTabCollapse;
